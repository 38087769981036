import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { Link } from "gatsby";

import {
  Container,
  Wrapper,
  Row,
  Box,
  Button,
  FlexButtons,
} from "../components/util";
import { CallToAction, Accordion } from "../components/site";

import Image from "../components/image";
import Layout from "../components/layout";
import Seo from "../components/seo";

import Steps from "../images/graphics/rebrand/Steps.svg";
import Podium from "../images/graphics/rebrand/Podium.svg";
import SnowBoard from "../images/graphics/rebrand/SnowBoard.svg";

const HeroContainer = styled(Container)`


`;


const Losenge = styled.div`
  position: absolute;
  top: 50%;
  width: 50vw;
  height: 585px;

  &.-extended-losenge{
    width: calc(50vw + 200px);
  }
  &.-mint {
    background-color: ${(props) => props.theme.colours.mint};
  }

  &.-honey {
    background-color: ${(props) => props.theme.colours.honey};
  }

  &.-deeppurple {
    background-color: ${(props) => props.theme.colours.deeppurple};
  }

  &.-sky {
    background-color: ${(props) => props.theme.colours.sky};
  }


  &.-ltr {
    left: -50px;
    transform: translateY(-50%);
    border-radius: 0 1000px 1000px 0;
  }

  &.-rtl {
    right: -50px;
    transform: translateY(-50%);
    border-radius: 1000px 0 0 1000px;
  }


  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    transform: rotate(0) translateY(0%) !important;
    top: 0;
    left: 0 !important;
    right: 0 !important;
    width: 100vw !important;
    border-radius: 0 !important;
    height: 100%;
`;

const LosengeContent = styled(Box)`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    text-align: left !important;
    padding-top: 0px;
  }
`;

const VideoContainer = styled.div`
  box-shadow: 0px 0px 100px 30px rgba(0, 0, 0, 0.25);
  background-color: red;
  border-radius: 20px;
  position: relative; 
  padding-bottom: 56.25%; 
  padding-top: 30px; 
  height: 0; 
  overflow: hidden; 
  iframe{
    position: absolute; top: 0; left: 0; width: 100%; height: 100%; 
  }

`

const KeyPointsContainer = styled.div`
  margin-bottom: 200px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-bottom: 0px;
  }
  padding-bottom: 100px;
  width: 100%;
  background-color: #A40D4C;
  min-height: 700px;
  h2{
    padding: 50px 0px;
    text-align: center;
  }
  .keypoints{
    display: flex;
    align-items: bottom;
    justify-content: center;
    flex-wrap: wrap;
  }
`
const KeyPoint = styled.div`
  min-width: 400px;
  margin: 50px 0px;
  width: 33%;
  padding: 0px 20px;
  text-align: center;
  h2{
    font-size: 32px;
    padding: 10px;
  }
  h4{
    font-size: 18px;
  }
  .keypoint-image-container{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0px 0px 20px 0px;
    img{
      height: 200px !important;
    }
  }
`

const FaqContainer = styled.div`
  background-color: #202020;

`

const KeyPoints = [
  {
    title: "Earn more on your terms",
    description:
      "Take pay day into your own hands, you decide your price. Find suitable shifts and send your hourly rate via the app - nice!",
    illustration: Steps
  },
  {
    title: "Schedule shifts to suit your flow",
    description:
      "Pick up shifts as they suit you. No contracts, no commitments, no worries. CrowdHire is built for the modern worker.",
    illustration: SnowBoard

  },
  {
    title: "Backed by FlexiTime",
    description:
      "A product by Flexitime, built in NZ, championing local hospo since 2008",
    illustration: Podium

  },
  
];

const Benefits = [
  {
    benefit: "Heading for this section",
    description:
      "Make an hourly rate offer when a shift thats the right fit becomes available",
    losenge_colour: "-mint",
    image: "Droppah_CrowdHire_Offer.png"
  },
  {
    benefit: "Heading for this section",
    description:
      "Make an hourly rate offer when a shift thats the right fit becomes available",
    losenge_colour: "-honey",
    image: "Droppah_CrowdHire_Listing.png",
  },
  {
    benefit: "Heading for this section",
    description:
      "Make an hourly rate offer when a shift thats the right fit becomes available",
    losenge_colour: "-sky",
    image: "Droppah_CrowdHire_Confirm.png",
  },
];

const FAQs = [
  {
    q: "What types of payment do you accept?",
    a: (
      <p>
        We accept Visa & MasterCard payments. Direct debit payments are
        available to customers in New Zealand.
      </p>
    ),
  },
  {
    q: "When will I be billed?",
    a: (
      <p>
        We bill at the start of each month for usage in the previous month.
      </p>
    ),
  },
  {
    q: "How does the pricing work?",
    a: (
      <>
        <p>
          Droppah is completely free for companies with up to 10 employees.
          You can access all features and there are no restrictions on your
          usage.
        </p>
        <p>
          For companies with 11+ employees the monthly billing is based on
          your usage. That way you’re not paying for inactive employees, such
          as seasonal employees during the off season.
        </p>
        <p>
          For the Time Clock Plan your monthly charge is based on the number
          of unique employees with a timesheet entry in the month. If you have
          an employee who isn’t included on a timesheet during the calendar
          month, you won’t be charged for them.
        </p>
        <p></p>
        <p>
          For The Works Plan your monthly charge is based on the number of
          unique employees in a roster or with a timesheet entry in the month.
          If you have an employee who isn’t included on a roster or timesheet
          during the calendar month, you won’t be charged for them.
        </p>
      </>
    ),
  },
  // {
  //   q: "Is there a discount for using multiple FlexiTime products?",
  //   a: (
  //     <>
  //       <p>
  //         If you use both{" "}
  //         <a href="https://www.payhero.co.nz" target="_blank">
  //           PayHero
  //         </a>{" "}
  //         and Droppah you’ll pay just $3 per employee per month for Droppah
  //         with no monthly base fee.
  //       </p>
  //       <p>
  //         Contact <a href="mailto:support@droppah.com">support@droppah.com</a>{" "}
  //         after subscribing to both products to have your discounted pricing
  //         applied.
  //       </p>
  //     </>
  //   ),
  // },
  {
    q: "Am I locked in?",
    a: (
      <p>
        No. Our standard subscriptions are pay-as-you-go and you can cancel
        your account at any time from your account settings.
      </p>
    ),
  },
  {
    q: "What happens if I cancel my account?",
    a: (
      <p>
        You can cancel at any time and we’ll send you a final invoice for your
        usage in the current billing period.
      </p>
    ),
  },
  {
    q: "Can I try before I buy?",
    a: (
      <>
        <p>
          You sure can! The Droppah Free plan gives you access to all the
          features for up to 10 employees. That means you can try Droppah out
          and do your testing before deciding to upgrade to a paid plan.
        </p>
      </>
    ),
  }]

const Home = (props) => {
  return (
    <Layout>
      <Seo
        title="Droppah | Rosters, Time Tracking & Attendance Software"
        description="Suss your schedule sitch in a sec with Droppah, staff scheduling, time tracking & attendance software for your business."
        pathname={props.location.pathname}
      />
      <HeroContainer>
      <Container
            css={{ backgroundColor: "transparent", overflow: "visible" }}
          >
            <Losenge
              className={"-ltr -deeppurple -extended-losenge"}
            />
            <Wrapper stackGap={250}>
              <Row
                alignCenter
                stackGap={50}
                flipRow={false}
              >
                <LosengeContent
                  size={60}
                  className={"-textLeft"}
                >
                  <Row stackGap={0}>
                    <Box>
                      <h2>Introducing Droppah CrowdHire</h2>
                      <h4>Flexible work for hospo legends. Earn more on your terms.</h4>
                    </Box>
                  </Row>
                </LosengeContent>
                <Box size={70}>
                  <VideoContainer>
                    <iframe src="https://www.youtube.com/embed/fn8GQs54bsU?si=ETQW7lhQb8N63VSC&autoplay=1&color=white&rel=0" title="Welcome to CrowdHire" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </VideoContainer>
                </Box>
              </Row>
            </Wrapper>
          </Container>
      </HeroContainer>

    <KeyPointsContainer>
        <h2>Be your own boss</h2>
        <div className="keypoints">
        {KeyPoints.map((item, i) => {
          return (
            <KeyPoint
              key={i}
              css={{ backgroundColor: "transparent", overflow: "visible" }}>
                <div className="keypoint-image-container">
                  <img src={item.illustration} />
                </div>

                <h2>{item.title}</h2>
                <h4>{item.description}</h4>

            </KeyPoint>
          );
        })}
        </div>
        
 
    </KeyPointsContainer>

      {Benefits.map((item, i) => {
        return (
          <Container
            key={i}
            css={{ backgroundColor: "transparent", overflow: "visible" }}
          >
            <Losenge
              className={`${item.losenge_colour} ${
                i % 2 !== 0 ? "-ltr" : "-rtl"
              }`}
            />
            <Wrapper stackGap={-0}>
              <Row
                alignCenter
                stackGap={50}
                flipRow={i % 2 !== 0 ? true : false}
              >
                <LosengeContent
                  size={55}
                  className={i % 2 !== 0 ? "-textLeft" : "-textRight"}
                >
                  <Row stackGap={0}>
                    <Box>
                      <h2>{item.benefit}</h2>
                      <h4>{item.description}</h4>
                    </Box>
                  </Row>
                </LosengeContent>
                <Box size={80}>
                  <Image
                    filename={item.image}
                    alt="Droppah"
                    maxWidth={850}
                    centerImage
                    addShadow
                  />
                </Box>
              </Row>
            </Wrapper>
          </Container>
        );
      })}
      <FaqContainer>
        <Wrapper maxWidth={1000}>
          <Box stackGap={60}>
            <h2 className="-textCenter">Frequently asked questions</h2>
            <Box stackGap={25}>
              {FAQs.map((item, i) => {
                return (
                  <Accordion key={i} title={item.q} light>
                    {item.a}
                  </Accordion>
                );
              })}
            </Box>
            <h4 className="-textCenter">
              More questions? <Link to="/contact">Talk to Sales</Link>.
            </h4>
          </Box>
        </Wrapper>
      </FaqContainer>
      <CallToAction />
    </Layout>
  );
};

export default Home;
